import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Dashbot Analytics combines with Genesys Customer Experience Platform to enable customers to increase user satisfaction and conversions through actionable insights.`}</em></p>
    <p><strong parentName="p">{`San Francisco, – June 25th, 2019 –`}</strong>{` Dashbot has announced its industry leading analytics platform is now available on the Genesys® AppFoundry, a dedicated customer experience marketplace. Genesys is the global leader in omnichannel customer experience and contact center solutions that power 25 billion of the world’s best customer experiences each year. The AppFoundry allows Genesys customers from all market segments to discover and rapidly deploy a broad range of solutions that make it easier to interact with consumers, engage employees and optimize their workforce.`}</p>
    <p>{`Dashbot is an analytics platform for conversational interfaces – chatbots, voice skills, and live agent chat. In addition to traditional metrics like engagement and retention, Dashbot provides conversational specific analytics like Natural Language Processing (NLP) response optimization, sentiment analysis, message funnels, and full transcripts. In addition, Dashbot has tools to take action on the data – like a live agent takeover of sessions going awry.`}</p>
    <p>{`“We are excited to work with Genesys to provide deeper insights into live agent and chatbot conversations for customers,” said Arte Merritt, Co-Founder and CEO of Dashbot.`}</p>
    <p>{`Dashbot integrates with the Genesys Customer Experience Platform to enable customers to better understand usage behavior resulting in increased user satisfaction, containment, and conversions through actionable insights and tools. Dashbot’s advanced NLP algorithms and deep conversational-specific analytics can help customers optimize response effectiveness.`}</p>
    <p>{`Dashbot is now available with the Genesys `}<a parentName="p" {...{
        "href": "https://www.genesys.com/purecloud"
      }}>{`PureCloud`}</a>{`® solution, a unified, all-in-one customer engagement and employee collaboration platform that is easy to use and quick to deploy. Built for people and built for change, PureCloud is designed for ease of use, radical simplification, and speed. It was the first customer experience application to use an API-first, microservices architecture — giving customers the option to extend the application or build a custom solution with Genesys integrations and APIs.`}</p>
    <p>{`To learn more about the specific features and benefits of Dashbot with Genesys, visit `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/features/genesys"
      }}>{`www.dashbot.io/features/genesys`}</a>{`.`}</p>
    <p><strong parentName="p">{`About Dashbot`}</strong>{`:`}<br parentName="p"></br>{`
`}{`Dashbot is an analytics platform for conversational interfaces – chatbots, voice skills, and live agent chat. In addition to traditional metrics like engagement and retention, Dashbot provides conversational-specific analytics like NLP response optimization, sentiment analysis, message funnels, and full transcripts. In addition, Dashbot has tools to take action on the data – like a live agent takeover of sessions going awry. Dashbot has over 18,000 chatbots on its platform and have processed more than 70 billion messages. Dashbot is funded by Bessemer Ventures, ffVC, Samsung, BMDI, Scrum Ventures, Rembrandt, R/GA, Recruit, and Run Capital.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      